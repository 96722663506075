import { get, set } from 'lodash'

import { getBase64Tag, Base64TagResult } from './getBase64Tag'
import { getContentTag } from './getContentTag'
import {
  ELSE_REGEXP,
  END_IF_REGEXP,
  END_LOOP_REGEXP,
  IF_REGEXP,
  LOOP_REGEXP,
  MULTIPLE_SIGN_REGEXP,
  SIGN_INNER_TYPES,
  SIGN_TYPES,
  SIMPLE_SIGN_REGEXP,
  UUID_REGEX,
} from '../../constants'
import { Field } from '../utils'
import { findFieldOptionsLabelByUuid } from './findFieldOptionsLabelByUuid'

interface SFDT {
  i?: Array<{
    tlp?: string
    cf?: object
  }>
  [key: string]: any
}

interface Variable {
  id: string
  name: string
  label: {
    FR: string
    EN: string
  }
  type: 'text'
}

const splitEJSTags = (sfdt: SFDT): SFDT => {
  const traverseAndSplit = (node: SFDT): void => {
    // Check if the node has an 'i' array to process
    if (Array.isArray(node.i)) {
      node.i.forEach((item, index) => {
        // If item has a 'tlp', check for EJS tags
        if (item.tlp) {
          // Split based on EJS tags using the updated regex
          const parts = item.tlp.split(/<%[-]?\s*.*?\s*%>/g)
          const ejsTags = item.tlp.match(/<%[-]?\s*.*?\s*%>/g) || []
          const ejsLoopStart = [...item.tlp.matchAll(LOOP_REGEXP)]
          // Prepare new 'i' array for the split items
          const newItems: any[] = []

          // Special case for the signature loop
          if (
            ejsLoopStart.length &&
            SIGN_INNER_TYPES.includes(node.i?.[index + 1]?.tlp || '')
          ) {
            newItems.push({
              ...item,
              tlp:
                item.tlp +
                (node.i?.[index + 1]?.tlp || '') +
                (node.i?.[index + 2]?.tlp || '') +
                (node.i?.[index + 3]?.tlp || '') +
                (node.i?.[index + 4]?.tlp || ''),
            })
            // Replace the original item with the new split items
            node.i?.splice(index, 1, ...newItems)
            // Remove other signature-related stuff
            node.i?.splice(index + 1, 4)
          } else if (ejsTags.length > 1) {
            // Other special case for the signature loop
            if (
              ejsLoopStart.length &&
              parts.length > 3 &&
              SIGN_INNER_TYPES.includes(parts[1])
            ) {
              newItems.push({
                ...item,
                tlp: ejsTags[0] + parts[1] + ejsTags[1] + parts[2] + ejsTags[2],
              })
              if (parts[3]) {
                newItems.push({
                  ...item,
                  tlp: parts[3],
                })
              }
            // If there are no parts and there are EJS tags, handle that case
            } else if (parts.length === 1 && parts[0] === '') {
              // If there are only EJS tags, just add them to the new items
              if (parts[1]){
                for (const tag of ejsTags) {
                    newItems.push({
                        ...item,
                        tlp: tag
                    });
                }}
            } else {
              // Combine parts with their respective EJS tags
              for (let i = 0; i < parts.length; i++) {
                if (parts[i].trim() !== '') {
                  // Only add non-empty parts
                  newItems.push({
                    ...item,
                    tlp: parts[i],
                  })
                }
                // Only add the EJS tag if it exists
                if (ejsTags[i]) {
                  newItems.push({
                    ...item,
                    tlp: ejsTags[i],
                  })
                }
              }
            }
            // Replace the original item with the new split items
            node.i?.splice(index, 1, ...newItems)
          }
        }
      })
    }

    // Recursively traverse deeper into the node object/array
    for (const key in node) {
      if (Array.isArray(node[key])) {
        for (const childNode of node[key]) {
          traverseAndSplit(childNode);
        }
      } else if (typeof node[key] === 'object' && node[key] !== null) {
        traverseAndSplit(node[key])
      }
    }
  }

  // Start traversal from the root of the provided SFDT object
  traverseAndSplit(sfdt)

  return sfdt // Return modified SFDT
}

export const convertEJSToTags = async (
  sfdt: string,
  variables?: Field[],
  language?: string
): Promise<{ sfdt: string; variables: Variable[] }> => {
  const result = splitEJSTags(JSON.parse(sfdt))

  const newVariables: Variable[] = []

  const traverseAndUpdate = async (
    node: any,
    path: string[] = []
  ): Promise<void> => {
    // Process the current node for EJS tags
    if (node?.tlp) {
      let img: Base64TagResult | undefined
      let id: string = ''

      const ejsVar = [...node.tlp.matchAll(/<%-\s*(.*)\s*%>/gm)]
      const ejsEndIf = [...node.tlp.matchAll(END_IF_REGEXP)]
      const ejsIf = [...node.tlp.matchAll(IF_REGEXP)]
      const ejsElse = [...node.tlp.matchAll(ELSE_REGEXP)]
      const ejsSimpleSign = [...node.tlp.matchAll(SIMPLE_SIGN_REGEXP)]
      const ejsMultipleSign = [...node.tlp.matchAll(MULTIPLE_SIGN_REGEXP)]
      const ejsPercentage = [
        ...node.tlp.matchAll(
          /<%-\s*Math\.trunc\(\((?:get|getRaw)\(['|"](.*)['|"]\)\s*\/\s*(?:get|getRaw)\(['|"](.*)['|"]\)\)\s*\*\s*100\)\s*\/\s*100\s*%>/gm
        ),
      ]
      const ejsLoop = [...node.tlp.matchAll(LOOP_REGEXP)]
      const ejsLinedString = [
        ...node.tlp.matchAll(
          /<%\s*(?:get|getRaw)\('(.*)'\)\.split\('\\n'\)\.map\((.[a-zA-Z0-9_.-]*)\s*=>\s*{\s*%>/gm
        ),
      ]
      const ejsEndLoop = [...node.tlp.matchAll(END_LOOP_REGEXP)]
      const ejsDisplayAddress = [
        ...node.tlp.matchAll(
          /<%-\s*displayAddress\({\s*((ignoreCountry):\s(true|false))?((element):\s(.[a-zA-Z0-9_.-]*))?,?\s*prefix:\s*['|"](.[a-zA-Z0-9_.-]*)['|"]\s*}\)\s*%>/gm
        ),
      ]
      const ejsGetFullInfo = [
        ...node.tlp.matchAll(
          /<%-\s*getFullInfo\({(.[a-zA-Z0-9_.-]*),\s*.*}\)\s*%>/gm
        ),
      ]

      // Determine what kind of EJS expression is in node.tlp and process it
      if (ejsLinedString.length) {
        id = ejsLinedString[0][0]
        img = await getBase64Tag(
          getContentTag(
            `loop-each-line:${ejsLinedString[0][1]}(${ejsLinedString[0][2]})`
          ),
          'loop'
        )
      } else if (ejsElse.length) {
        id = ejsElse[0][0]
        img = await getBase64Tag(getContentTag('else'), 'condition')
      } else if (ejsGetFullInfo.length) {
        id = ejsGetFullInfo[0][0]
        const prefix = ejsGetFullInfo[0][1]
        img = await getBase64Tag(
          getContentTag(`getFullInfo(${prefix})`),
          'variable'
        )
      } else if (ejsDisplayAddress.length) {
        id = ejsDisplayAddress[0][0]
        const filteredEjs = ejsDisplayAddress[0].filter(Boolean)
        const element = filteredEjs[2] === 'element' ? filteredEjs[3] : null
        const prefix = filteredEjs[4]
        img = await getBase64Tag(
          getContentTag(`displayAddress(${element || prefix})`),
          'variable'
        )
      } else if (ejsPercentage.length) {
        id = ejsPercentage[0][0]
        img = await getBase64Tag(
          getContentTag(
            `percentage:${ejsPercentage[0][1]}/${ejsPercentage[0][2]}`
          ),
          'variable'
        )
      } else if (ejsMultipleSign.length) {
        id = ejsMultipleSign[0][0]
        const type: keyof typeof SIGN_TYPES = ejsMultipleSign[0][3] // Assurez-vous que `ejsMultipleSign[0][3]` est une des clés valides
        const label = `${SIGN_TYPES[type]}:${ejsMultipleSign[0][1]}.${ejsMultipleSign[0][5]}`
        img = await getBase64Tag(getContentTag(label), 'sign')
      } else if (ejsLoop.length) {
        id = ejsLoop[0][0]
        let label = ejsLoop[0][1]
        if (variables){
          label = label.replace(UUID_REGEX, (val: string) => findFieldOptionsLabelByUuid(variables, val, language) || val)
        }
        img = await getBase64Tag(getContentTag(`loop:${label}`), 'loop')
      } else if (ejsEndLoop.length) {
        id = ejsEndLoop[0][0]
        img = await getBase64Tag(getContentTag('endloop'), 'loop')
      } else if (ejsSimpleSign.length) {
        id = ejsSimpleSign[0][0]
        const type: keyof typeof SIGN_TYPES = ejsSimpleSign[0][1]
        const label = `${SIGN_TYPES[type]}:${ejsSimpleSign[0][2]}`
        img = await getBase64Tag(getContentTag(label), 'sign')
      } else if (ejsVar.length && ejsVar?.[0]?.[1]) {
        id = ejsVar[0][0]
        const getName = [
          ...ejsVar[0][1].matchAll(/(?:get|getRaw)\(['|"](.*)['|"]\)/gm),
        ]
        const getBasedName = [
          ...ejsVar[0][1].matchAll(
            /(.*)\((?:get|getRaw)\(['|"](.*)['|"]\)[,]?\s*(?:{.*})?\)/gm
          ),
        ]
        const getMultipleName = [
          ...ejsVar[0][1].matchAll(/(.*)\.(?:get|getRaw)\(['|"](.*)['|"]\)/gm),
        ]
        if (getBasedName.length || getName.length || getMultipleName.length) {
          let name = getName[0][1]
          let label: string
          if (getMultipleName.length) {
            name = getMultipleName[0][2]
            label = `${getMultipleName[0][1]}.${getMultipleName[0][2]}`
          } else if (getBasedName.length) {
            name = getBasedName[0][2]
            label = `${getBasedName[0][1]}(${getBasedName[0][2]})`
          }
          img = await getBase64Tag(getContentTag(label || name), 'variable')
          if (!newVariables.find(v => v.name === name)) {
            newVariables.push({
              id,
              name,
              label: {
                FR: name,
                EN: name,
              },
              type: 'text',
            })
          }
        } else {
          img = await getBase64Tag(getContentTag(ejsVar[0][1]), 'variable')
        }
      } else if (ejsEndIf.length) {
        id = ejsEndIf[0][0]
        img = await getBase64Tag(getContentTag('endif'), 'condition')
      } else if (ejsIf.length) {
        id = ejsIf[0][0]
        const base = ejsIf[0][1]
        let label = base.replace(
          /get\(['|"](.[a-zA-Z0-9_.-]*)['|"]\)/gm,
          (_: any, content: string) => content
        )
        if (variables){
          label = label.replace(UUID_REGEX, (val: string) => findFieldOptionsLabelByUuid(variables, val, language) || val)
        }
        img = await getBase64Tag(getContentTag(`if:(${label})`), 'condition')
      }

      // Handle the image generated and add it to the result
      if (img) {
        const imgs = get(result, 'imgs')
        const index =
          Object.keys(imgs).reduce(
            (acc, val: any) => (val * 1 > acc ? val * 1 : acc),
            0
          ) + 1
        set(result, 'imgs', {
          ...imgs,
          [index]: [img.b64, img.b64],
        })
        set(result, path, {
          cf: node.cf ?? {},
          img: index.toString(),
          mtimg: index.toString(),
          imf: 1,
          comp: false,
          w: img.width / 1.333,
          h: img.height / 1.333,
          n: '',
          at: id,
          tt: '',
          v: 0,
          vp: 0,
          hp: 0,
          ao: 0,
          tws: 0,
          b: 0,
          db: 0,
          dl: 0,
          dr: 0,
          dt: 0,
          lc: 0,
        })
      }
    }

    // Recursively traverse deeper into the node object/array
    for (const key in node) {
      if (Array.isArray(node[key])) {
        await Promise.all(
          node[key].map(async (childNode, index) => {
            await traverseAndUpdate(childNode, [...path, key, String(index)])
          })
        )
      }
    }
  }

  // Start traversal from the root of the result object
  await traverseAndUpdate(result)

  return {
    sfdt: JSON.stringify(result),
    variables: newVariables,
  }
}
